import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Tag } from "../components/Tag/Tag";
import MdxImgFrame from "../components/MDX/MdxImgFrame/MdxImgFrame";
import MdxGrid from "../components/MDX/MdxGrid/MdxGrid";
import { CodeInline } from "../components/Code/Code";
import * as styles from "../styles/pages/templates/BlogPostTemplate.module.scss";
import { mdx__template } from "../styles/pages/templates/MdxTemplate.module.scss";

import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

const BlogPostTemplate = ({ data }) => {
  const { frontmatter, body } = data.mdx;
  const { type, date, tags, title } = frontmatter;

  let arrayofTags = tags ? tags : [];

  const crumbs = {
    trail: [
      {
        name: "All Writing & Notes",
        url: "/writing",
      },
    ],
    current: {
      name: title,
    },
  };

  return (
    <div className={styles.content_wrapper}>
      <Breadcrumb config={crumbs} />
      <header>
        <div className={styles.dateWrapper}>
          <div className={styles.line}></div>
          <span>{type}</span>
          <div className={styles.line}></div>
        </div>
        <h1>{title}</h1>
        <div>
          <ul className={styles.tags_wrapper}>
            {arrayofTags.map((tag, i) => (
              <li key={i}>
                <Tag name={tag} />
              </li>
            ))}
          </ul>
        </div>
        <span className={styles.date}>Last Updated: {date}</span>
      </header>
      <article className={mdx__template}>
        <MDXProvider components={{ MdxImgFrame, MdxGrid, CodeInline }}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </article>
      <div style={{ textAlign: "center" }}>(Back to Top)</div>
    </div>
  );
};

export default BlogPostTemplate;

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
      }),
    }),
  }),
};

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        slug
        title
        type
        date
        tags
      }
    }
  }
`;
