import * as React from "react";
import * as styles from "./Tag.module.scss";

export const Tag = ({ name }) => {
  return (
    <div className={styles.tag}>
      <span>{name}</span>
    </div>
  );
};
